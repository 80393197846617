import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/thank-you-section1";
import SEO from "../components/seo"

export default function Invalid() {
  return (
      <Layout
        LogoColor={props => props.theme.colors.white} 
        HeaderMenuColor={props => props.theme.colors.white} 
        firstSectionBGColor={props => props.theme.colors.primary_violet}

        //button
        HeaderButtonColor={props => props.theme.buttons.secondary_small_button_white_violet.normal.color} 
        HeaderButtonBorder={props => props.theme.buttons.secondary_small_button_white_violet.normal.border_color} 
        HeaderButtonBackground={props => props.theme.buttons.secondary_small_button_white_violet.normal.background_color}
        
        HeaderButtonColorHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.color}
        HeaderButtonBorderHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.border_color} 
        HeaderButtonBackgroundHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.background_color}

        HeaderButtonColorPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.color}
        HeaderButtonBorderPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.border_color} 
        HeaderButtonBackgroundPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.background_color}
      >
          <SEO title={"Page Not Found"} description={"Oops, the page you are looking for has been removed or relocated!"} /> 
          <Section1 
            BackgroundColor={props => props.theme.colors.primary_violet}
            HeaderText="Page Not Found"
            DescriptionText="Oops, the page you are looking for has been removed or relocated!"
          >
          </Section1>
      </Layout>
  )
}
